import './App.css';
import axios from 'axios';
import { useState, useEffect } from 'react';

const isProduction = process.env.NODE_ENV === 'production';
const serverUrl = !isProduction ? 'http://localhost:5000' : 'https://search.idcgames.com/api';

function App() {
  const [searchText, setSearchText] = useState("");
  const [searchCat, setSearchCat] = useState([]);
  const [searchTag, setSearchTag] = useState([]);
  const [searchLang, setSearchLang] = useState([]);
  const [searchType, setSearchType] = useState([]);
  const [filterTick, setFilterTick] = useState(0);

  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [gameCount, setGameCount] = useState(null);
  const [queryTime, setQueryTime] = useState(0);
  const [lastQuery, setLastQuery] = useState('');

  const [filterData, setFilterData] = useState({});
  const { gameCategories, gameTags, gameLangs, typeOfPlayers } = filterData;
  
  function handleTextChange(e) {
    setSearchText(e.target.value);
  }

  function handleChoiceChange(id, type) {
    if(type === 'category'){
      if(searchCat.includes(id)){
        const index = searchCat.indexOf(id);
        searchCat.splice(index, 1);
      }else{
        searchCat.push(id);
      }
      setSearchCat(searchCat);
    } else if(type === 'tag'){
      if(searchTag.includes(id)){
        const index = searchTag.indexOf(id);
        searchTag.splice(index, 1);
      }else{
        searchTag.push(id);
      }
      setSearchTag(searchTag);
    } else if(type === 'lang'){
      if(searchLang.includes(id)){
        const index = searchLang.indexOf(id);
        searchLang.splice(index, 1);
      }else{
        searchLang.push(id);
      }
      setSearchLang(searchLang);
    } else if(type === 'typeOfPlayer'){
      if(searchType.includes(id)){
        const index = searchType.indexOf(id);
        searchType.splice(index, 1);
      }else{
        searchType.push(id);
      }
      setSearchType(searchType);
    }

    setFilterTick(filterTick+1);
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      loadGames();
    }
  }

  function handleClick(game, action) {
    if (action === "delete") {
      game.deleting = true;
      setGames({ games });
      axios.delete(`${serverUrl}/games/${game.id_idcgame}`)
        .then(() => {
          console.log('gamed leted');
          const index = games.findIndex(g => g.id_idcgame === game.id_idcgame);
          if(index !== -1){
            games.splice(index, 1);
          }
        })
        .catch(() => game.deleting = false)
        .finally(() => setGames(games));
    } else if(action === 'update'){
      game.updating = true;
      setGames(games);
      axios.put(`${serverUrl}/games/${game.id_idcgame}`, {}).finally(() => {
        game.updating = false;
        setGames(games);
      });
    }
  }

  function loadFilterData(){
    const url = `${serverUrl}/filter-data`;
    axios.get(url)
      .then(response => {
        setFilterData(response.data);
      })
      .catch(err => console.log('Error getting filter data', err));
  }

  function loadGames(forcePage = null, forcePerPage = null){
    if(loading){
      return;
    }

    const queryParams = [
      `text=${searchText}`,
      `category=${searchCat.join(",")}`,
      `tag=${searchTag.join(",")}`,
      `lang=${searchLang.join(",")}`,
      `type_of_player=${searchType.join(",")}`,
      `page=${forcePage || page}`,
      `per_page=${forcePerPage || perPage}`
    ];

    const loadGameCount = (forcePage || forcePerPage) ? 0 : 1;
    queryParams.push(`load_game_count=${loadGameCount}`);

    const url = `${serverUrl}/games?${queryParams.join("&")}`;
    
    setLoading(true);
    axios.get(url)
      .then(response => {
        const { games, total, page, perPage, timeEllapsed, gameCount } = response.data;
        setTotalCount(total);
        setPage(page);
        setPerPage(perPage);
        setGames(games || []);
        setQueryTime(timeEllapsed || 0);
        setLastQuery(url);
        if(gameCount){
          setGameCount(gameCount);
        }
      })
      .catch(err => {
        setTotalCount(0);
        setPage(1);
        setPerPage(perPage);
        setGames([]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    loadFilterData();
  }, []);

  useEffect(() => {
    loadGames();
  }, [filterTick]);

  const renderPaginator = () => {
    const start = (page-1)*perPage + 1;
    const end = Math.min(page*perPage, totalCount);

    return (
      <div>
        {lastQuery && <p className="api-call"><b>API:</b> {lastQuery}</p>}
        <div className='paginator'>
          Showing: {start}-{end} / {totalCount}

          <div>
            <button onClick={() => loadGames(page-1)}>Prev Page</button>
            <button onClick={() => loadGames(page+1)}>Next Page</button>
          </div>

          <div>
            Per Page: &nbsp;
            <select defaultValue={perPage} onChange={evt => loadGames(page, evt.target.value)}>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div>
            Query Time: { loading ? '---' : `${queryTime}ms` }
          </div>
        </div>
      </div>
    );
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <input type="text" className='text-search'
          placeholder='Type & Press Enter to search game name...' 
          value={searchText}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
        <div className="filter-choice-container">
          <div className="filter-choice filter-choice-category">
            <h3>Category</h3>
            {gameCategories && gameCategories.map(gc => 
              <div key={gc.id}>
                <input type="checkbox" id={`cat-${gc.id}`} value={gc.id} name="select-category" onChange={evt => handleChoiceChange(gc.id, 'category')} />
                <label htmlFor={`cat-${gc.id}`}>
                  {gc.name}
                  {(gameCount&&gameCount.categories) && <span className='game-count'>{`(${gameCount.categories[gc.id] || 0})`}</span>}
                </label>
              </div>
            )}
          </div>
          <div className="filter-choice filter-choice-tag">
            <h3>Tag</h3>
            {gameTags && gameTags.map(gt => 
              <div key={gt.id}>
                <input type="checkbox" id={`tag-${gt.id}`} value={gt.id} name="select-tag" onChange={evt => handleChoiceChange(gt.id, 'tag')} />
                <label htmlFor={`tag-${gt.id}`}>
                  {gt.name}
                  {(gameCount&&gameCount.tags) && <span className='game-count'>{`(${gameCount.tags[gt.id] || 0})`}</span>}
                </label>
              </div>
            )}
          </div>
          <div className="filter-choice filter-choice-lang">
            <h3>Lang</h3>
            {gameLangs && gameLangs.map(gl => 
              <div key={gl.id}>
                <input type="checkbox" id={`tag-${gl.id}`} value={gl.id} name="select-lang" onChange={evt => handleChoiceChange(gl.code, 'lang')} />
                <label htmlFor={`tag-${gl.id}`}>{gl.code}</label>
              </div>
            )}
          </div>
          <div className="filter-choice filter-choice-type-player">
            <h3>Type of Player</h3>
            {typeOfPlayers && typeOfPlayers.map(gl => 
              <div key={gl.id}>
                <input type="checkbox" id={`tag-${gl.id}`} value={gl.slug} name="select-type-player" onChange={evt => handleChoiceChange(gl.slug, 'typeOfPlayer')} />
                <label htmlFor={`tag-${gl.id}`}>{gl.name}</label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  const renderGames = () => {
    if(!games || !games.length){
      return (
        <div className='empty-data-indicator'>No games found.</div>
      );
    }

    return (
      <table className="games-container" key={games.length}>
        <thead>
          <tr>
            <th>No</th>
            <th># ID</th>
            <th>Logo</th>
            <th>Name</th>
            <th>Categories</th>
            <th>Tags</th>
            <th>Langs</th>
            <th>Type of Player</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {games.map(renderGame)}
        </tbody>
      </table>
    );

    function renderGame(game, index){
      const no = perPage*(page-1) + index + 1;
      const categories = (game.categories || []).map(cId => {
        if(!gameCategories){
          return '';
        }

        const index = gameCategories.findIndex(gc => cId === gc.id);
        if(index !== -1){
          return gameCategories[index].name;
        }

        return '';
      }).join(", ");
      const tags = (game.tags || []).map(tId => {
        if(!gameTags){
          return '';
        }

        const index = gameTags.findIndex(gt => tId === gt.id);
        if(index !== -1){
          return gameTags[index].name;
        }

        return '';
      }).join(", ");
      const playerType = (game.playerTypes || []).map(slug => {
        if(!typeOfPlayers){
          return '';
        }

        const index = typeOfPlayers.findIndex(gt => slug === gt.slug);
        if(index !== -1){
          return typeOfPlayers[index].name;
        }

        return '';
      }).join(", ");
      // const langs = (game.langs || []).map(lang => <img alt={lang} key={lang} src={`/flags/${lang}.svg`} />);
      const langs = (game.langs || []).join(", ")

      return (
        <tr key={game._id} className="row-game">
          <td className="no">{no}</td>
          <td className="id">{game.id_idcgame}</td>
          <td><img alt="logo" className="logo" src={game.logo}/></td>
          <td className="name">{game.name || 'Unnamed game'}</td>
          <td className="categories">{categories}</td>
          <td className="tags">{tags}</td>
          <td className="tags">{playerType}</td>
          <td className="langs">
            <div className="langs-container">{langs}</div>
          </td>
          <td className="actions">
            <h4>{game.updating && 'Updating...'}</h4>
            <h4>{game.deleting && 'Deleting...'}</h4>
            <div className="card-actions">
              <button className="button" onClick={() => handleClick(game, 'update')}>Update Me</button>
              <button className="button" onClick={() => handleClick(game, 'delete')}>Delete Me</button>
            </div>
          </td>
        </tr>
      );
    }
  }

  return (
      <div className="app">
        <div>
          <h1>Search IDC Games</h1>

          {renderFilter()}

          {loading && <div className='loading-indicator'>Loading...</div>}

          {!loading &&
          <div>
            { renderPaginator() }
            { renderGames() }
          </div>}
        </div>
      </div>
  );
}

export default App;
